import React from "react"

import Model from "./Model"

import "../styles/components/HeaterInfo.styl"
import { StaticImage } from "gatsby-plugin-image"

export default function HeaterInfo({
  heaterData,
  typeOfHeater,
  modelInfo,
  handleOnClickModel,
  type,
}) {
  const img1 = "../images/heater/calentador-de-alta-presión.png"
  const img2 = "../images/heater/calentador-de-baja-presión.png"

  const features = modelInfo.features.toLowerCase()

  return (
    <section className="HeaterInfo h-i">
      <div className="h-i__image">
        {typeOfHeater === "Baja Presión" && <StaticImage src={img2} />}
        {typeOfHeater === "Alta Presión" && <StaticImage src={img1} />}
      </div>
      <div className="h-i__features features">
        <div className="features__title">Características</div>
        <div className="features__subtitle">
          Calentadores de{` ` + typeOfHeater}
        </div>
        <div className="features__model">
          Modelo: {" " + modelInfo.modelCapacity}
        </div>
        <div className="features__details">{" " + features}</div>
        <div className="features__dimensions">
          Dimensiones: <br />
          Ancho: {" " + modelInfo.width} <br /> Largo:
          {" " + modelInfo.large}
        </div>
        <i className="features__aclaration">
          Imagen de referencia. El calentador puede lucir diferente <br />
          dependiendo de las capacidades del modelo
        </i>
      </div>
      <div className="h-i__available-models models">
        <div className="models__title">Modelos disponibles:</div>
        <div className="models__list">
          {heaterData.heater !== undefined &&
            heaterData.heater.map(param => (
              <Model
                details={param}
                handleOnClickModel={handleOnClickModel}
                type={type}
              />
            ))}
        </div>
      </div>
    </section>
  )
}
