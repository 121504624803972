import React from "react"
import NavbarItemHeaters from "./NavbarItemHeaters"
import "../styles/components/SecondaryNavbar.styl"

export default function SecondaryNavbarHeaters({ sections, handleOnClick }) {
  return (
    <nav className="SNavbar">
      {sections !== undefined && (
        <ul className="nav-list">
          {sections.map(params => (
            <NavbarItemHeaters
              key={params.type}
              handleOnClick={handleOnClick}
              rightToSelected={false}
              leftToSelected={true}
              type={params.type}
              type__className={params.className}
            />
          ))}
        </ul>
      )}
    </nav>
  )
}
