import React, { useContext } from "react"
import { MyContext } from "./context"

import "../styles/components/NavbarItem.styl"

export default function NavbarItemHeaters({
  key,
  handleOnClick,
  type,
  type__className,
}) {
  const context = useContext(MyContext)
  function handleOnClickBackgroundColor(sectionClassName) {
    const newSelected = "isSelected__" + sectionClassName
    context.changeSelectedNavbarSectionHeaters(newSelected)
  }

  return (
    <>
      {context.selectedNavbarHeaters === "isSelected__" + type__className ? (
        <li
          key={key}
          className={`text-wrapper ${context.selectedNavbarHeaters}--corners-rounded ${context.selectedNavbarHeaters}`}
        >
          <li
            className={`navbar-text`}
            onClick={() => {
              handleOnClick(type)
              handleOnClickBackgroundColor(type__className)
            }}
          >
            {type}
          </li>
        </li>
      ) : (
        <li key={key} className={`text-wrapper`}>
          <li
            className={`navbar-text`}
            onClick={() => {
              handleOnClick(type)
              handleOnClickBackgroundColor(type__className)
            }}
          >
            {type}
          </li>
        </li>
      )}
    </>
  )
}
