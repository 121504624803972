import React, { useContext } from "react"

import { MyContext } from "./context"
import { StaticImage } from "gatsby-plugin-image"

import img from "../images/logos/photon-mini-logo-blue.jpg"

import "../styles/components/Model.styl"

export default function Model({ details, handleOnClickModel, type }) {
  const context = useContext(MyContext)

  function setModelLowPressure(model) {
    context.changeSelectedModelLowPressure(model)
  }
  function setModelHighPressure(model) {
    context.changeSelectedModelHighPressure(model)
  }
  return (
    <>
      {context.selectedModelHighPressure !== undefined && (
        <>
          {type === "lowPressure" && (
            <div
              role="option"
              className="Model"
              onClick={() => {
                handleOnClickModel(details)
                setModelLowPressure({
                  heaterModel: details.modelCapacity,
                })
              }}
            >
              <div className="model__image">
                {context.selectedModelLowPressure.heaterModel ===
                details.modelCapacity ? (
                  <StaticImage
                    width="29"
                    height="29"
                    src="../images/logos/phocon-mini-logo-colors.png"
                  />
                ) : (
                  <img src={img} alt="" srcset="" />
                )}
              </div>
              <div className="model__title">{details.modelCapacity}</div>
            </div>
          )}
          {type === "highPressure" && (
            <div
              role="option"
              className="Model"
              onClick={() => {
                handleOnClickModel(details)
                setModelHighPressure({
                  heaterModel: details.modelCapacity,
                })
              }}
            >
              <div className="model__image">
                {context.selectedModelHighPressure.heaterModel ===
                details.modelCapacity ? (
                  <StaticImage
                    width="29"
                    height="29"
                    src="../images/logos/phocon-mini-logo-colors.png"
                  />
                ) : (
                  <img src={img} alt="" srcset="" />
                )}
              </div>{" "}
              <div className="model__title">{details.modelCapacity}</div>
            </div>
          )}
        </>
      )}
    </>
  )
}
