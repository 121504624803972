import React, { createContext, useState } from "react"

export const MyContext = createContext()

export const MyProvider = ({ children }) => {
  const [selectedNavbarPanels, setSelectedNavbarPanels] = useState(
    "isSelected__business"
  )
  const [selectedNavbarHeaters, setSelectedNavbarHeaters] = useState(
    "isSelected__highPressure"
  )
  const [selectedModelLowPressure, setSelectedModelLowPressure] = useState({
    heaterModel: "FRA-150 lts",
  })
  const [selectedModelHighPressure, setSelectedModelHighPressure] = useState({
    heaterModel: "COR-380 lts",
  })
  const changeSelectedModelLowPressure = element => {
    setSelectedModelLowPressure(element)
  }
  const changeSelectedModelHighPressure = element => {
    setSelectedModelHighPressure(element)
  }

  const changeSelectedNavbarSectionPanels = element => {
    setSelectedNavbarPanels(element)
  }
  const changeSelectedNavbarSectionHeaters = element => {
    setSelectedNavbarHeaters(element)
  }
  return (
    <MyContext.Provider
      value={{
        selectedNavbarPanels,
        selectedNavbarHeaters,
        selectedModelLowPressure,
        selectedModelHighPressure,
        changeSelectedModelLowPressure,
        changeSelectedModelHighPressure,
        changeSelectedNavbarSectionPanels,
        changeSelectedNavbarSectionHeaters,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}
