import * as React from "react"
import { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondaryNavbarHeaters from "../components/SecondaryNavbarHeaters"
import HeaterInfo from "../components/HeaterInfo"

import { MyProvider } from "../components/context"
import CallToActionButton from "../components/CallToActionButton"

export default function PanelesSolares({ data }) {
  const [typeOfHeaterClients, setTypeOfHeaterClients] = useState("Alta Presión")
  const [modelInfo0, setModelInfo0] = useState({
    modelCapacity: "COR-380 lts",
    features: "30 Tubos / 8 – 10 Servicios",
    width: "259 CM",
    large: "196 CM",
  })
  const [modelInfo1, setModelInfo1] = useState({
    modelCapacity: "FRA-150 lts",
    features: "12 Tubos / 2 – 3 Servicios",
    width: "147 CM",
    large: "196 CM",
  })
  function handleOnClickModel0(param) {
    setModelInfo0(param)
  }
  function handleOnClickModel1(param) {
    setModelInfo1(param)
  }
  function handleOnClick(typeOfClients) {
    if (typeOfClients === "Alta Presión") {
      setTypeOfHeaterClients("Alta Presión")
    } else setTypeOfHeaterClients("Baja Presión")
  }
  const heaterData = data.allStrapiHeaters.nodes[0].typeHeater
  return (
    <MyProvider>
      <Layout>
        <Seo title="Calentadores Solares" />
        <SecondaryNavbarHeaters
          sections={[
            { type: "Alta Presión", className: "highPressure" },
            { type: "Baja Presión", className: "lowPressure" },
          ]}
          sectionName="heaters"
          handleOnClick={handleOnClick}
        />
        {typeOfHeaterClients === "Alta Presión" && (
          <HeaterInfo
            heaterData={heaterData[0]}
            typeOfHeater={typeOfHeaterClients}
            handleOnClickModel={handleOnClickModel0}
            modelInfo={modelInfo0}
            type="highPressure"
          />
        )}
        {typeOfHeaterClients === "Baja Presión" && (
          <HeaterInfo
            heaterData={heaterData[1]}
            typeOfHeater={typeOfHeaterClients}
            handleOnClickModel={handleOnClickModel1}
            modelInfo={modelInfo1}
            type="lowPressure"
          />
        )}
        <CallToActionButton />
      </Layout>
    </MyProvider>
  )
}

export const query = graphql`
  query getHeaters {
    allStrapiHeaters {
      nodes {
        typeHeater {
          name
          heater {
            modelCapacity
            width
            large
            features
          }
        }
      }
    }
  }
`
